import * as React from "react"
import { Helmet } from "react-helmet"

import CssBaseline from '@mui/material/CssBaseline';
import ButtonAppBar from '../components/buttonAppBar'

// styles
const pageStyles = {
  color: "#232129",
  padding: 0,
  margin: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64
}

// markup
const AboutPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
        <link rel="canonical" href="http://khao.so/about" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
        <meta name="description" content="This is our about page!" />
      </Helmet>

      <CssBaseline />
      <ButtonAppBar />

      <h1 style={headingStyles}>
        Soon we will provide more info!
      </h1>
    </main>
  )
}

export default AboutPage
